import React, { Component } from "react";
import "./../css/main.css";
import particlesJS from "particles.js";
import * as config from "../config/particles_js";

const _particles = () => {
  const particles_Config = config.particles_config;
  window.particlesJS("particles-js", particles_Config);
};

class Home extends Component {
  componentDidMount = () => {
    _particles();
  };

  render() {
    return (
      <section className="particle-section">
        <div id="particles-js" />
        <div className="typewriter-container">
          <div class="typewriter">
            <h1>Hello World This is Shreyan</h1>
          </div>
        </div>
      </section>
    );
  }
}
export default Home;
