import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Home from './../components/home';

const Fullpage = () => (
  <ReactFullpage
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <Home />
          </div>
          {/* <div className="section">
          </div> */}
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default Fullpage;